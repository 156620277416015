import { TreeModel, TreeNode } from "@ali-hm/angular-tree-component";

export interface IDisplayGroupTree {
  model?: TreeModel;
  node: TreeNode;
}

export enum ActionType {
  EDIT = "Update",
  ADD = "Add New",
}

export interface IDisplayGroupForm {
  payload: any;
  id: any;
  action: ActionType;
  prevGroupSetCode?: number;
}

export enum DisplayGroupCategory {
  DISPLAY_GROUP_SET = "display-group-set",
  DISPLAY_GROUP = "display-group",
}

export enum DisplayGroupStatus {
  HIDE = 0,
  SHOW = 1,
  ARCHIVE = 2,
}

export enum DisplayGroupTitle {
  DISPLAY_GROUP = 'Display Group',
  DISPLAY_GROUP_SET = 'Display Group Set',
}
