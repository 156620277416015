import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { CacheService } from "./cache.service";
import { APP_CONFIG, LOCALE_CONFIG, LocaleCode } from "src/app/config";
import { filter, map } from "rxjs/operators";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { SiteConfigService } from "./site-config.service";
import { ActivatedRoute } from "@angular/router";

export interface LocaleChangeEvent {
  lang: string;
  translation?: string;
}

@Injectable()
export class LocaleService {
  private langStream = new BehaviorSubject<LocaleChangeEvent>(null);
  private _selectedLang: string;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    @Inject(LOCALE_CONFIG) private locales,
    private cacheService: CacheService,
    private siteConfigService: SiteConfigService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {}

  set selectedLang(val: string) {
    this._selectedLang = val;
  }

  get onLangChange(): Observable<LocaleChangeEvent> {
    return this.langStream.asObservable().pipe(filter((res) => res !== null));
  }

  get selectedLang() {
    return this._selectedLang || LocaleCode.FI;
  }

  getAvailableLocales() {
    return this.locales;
  }

  getPreferredLang() {
    const sessionLocale = this.cacheService.get(
      this.appConfig.sessionLocaleKey
    );

    return sessionLocale || this.appConfig.defaultLocale;
  }

  getParsedResult(key: string, _translation: string) {
    return key;
  }

  setPreferredLang(code: LocaleCode) {
    this.cacheService.set(this.appConfig.sessionLocaleKey, code);

    this.pushLang(code);
  }

  setDefaultLang(lang?: LocaleCode) {
    const sessionLocale = this.cacheService.get(
      this.appConfig.sessionLocaleKey
    );

    if (sessionLocale) {
      this.pushLang(sessionLocale);
      return;
    }

    const defaultLocale =
      lang ||
      this.siteConfigService.getItem("lang") ||
      this.appConfig.defaultLocale;

    this.cacheService.set(this.appConfig.sessionLocaleKey, defaultLocale);
    this.pushLang(defaultLocale);
  }

  pushLang(code: string) {
    this.langStream.next({ lang: code });

    this.selectedLang = code;
    this.translateService.use(code);
  }

  reset() {
    this.cacheService.remove(this.appConfig.sessionLocaleKey);
    this.langStream.next(null);
  }

  getLanguageStream(): Observable<string> {
    return this.onLangChange.pipe(
      map((res) => {
        return res.lang;
      })
    );
  }

  onTranslationChange(): Observable<LangChangeEvent> {
    return this.translateService.onLangChange;
  }

  trans(query, params = null) {
    let mode = this.route.snapshot.queryParams["mode"];
    let translatedText = "";

    if (mode === "debug") {
      translatedText = `{{${query}}} - `;
    }

    const text = this.translateService.instant(query, params);

    if (text.value) {
      translatedText += text.value;
    } else {
      translatedText = text;
    }

    return translatedText;
  }
}
