import { Injectable } from "@angular/core";

import { AngularFireAuth } from "@angular/fire/compat/auth";

@Injectable()
export class FirebaseService {
  constructor(private firebaseAuth: AngularFireAuth) {}

  get onAuthStateListener() {
    return this.firebaseAuth.onAuthStateChanged;
  }

  get onTokenStateListener() {
    return this.firebaseAuth.onIdTokenChanged;
  }

  signInWithCustomToken(token: string) {
    return this.firebaseAuth.signInWithCustomToken(token).then(async (res) => {
      const accessToken = await res.user.getIdToken();

      return {
        token: accessToken,
      };
    });
  }

  refreshToken() {
    return this.firebaseAuth.currentUser.then((user) => {
      return user?.getIdToken(true);
    });
  }

  getAuthUser() {
    return this.firebaseAuth.user;
  }

  signOut() {
    return this.firebaseAuth.signOut();
  }
}
