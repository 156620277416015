import { isEmptyVal, isNumeric } from "./helper";
import * as moment from "moment";
import { UntypedFormGroup } from "@angular/forms";

export class Utils {
  static resolveProp = (obj, propString) => {
    if (!propString) return obj;

    var prop,
      props = propString.split(".");

    for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
      prop = props[i];

      var candidate = obj[prop];
      if (candidate !== undefined) {
        obj = candidate;
      } else {
        break;
      }
    }
    return obj[props[i]];
  };

  static updateObjProp(obj, propPath, value) {
    const [head, ...rest] = propPath.split(".");

    !rest.length
      ? (obj[head] = value)
      : Utils.updateObjProp(obj[head], rest.join("."), value);
  }

  // Deep merge two objects
  static mergeDeep(...objects) {
    const isObject = (obj) => obj && typeof obj === "object";

    return objects.reduce((prev, obj) => {
      Object.keys(obj).forEach((key) => {
        const pVal = prev[key];
        const oVal = obj[key];

        if (Array.isArray(pVal) && Array.isArray(oVal)) {
          prev[key] = pVal.concat(...oVal);
        } else if (isObject(pVal) && isObject(oVal)) {
          prev[key] = Utils.mergeDeep(pVal, oVal);
        } else {
          prev[key] = oVal;
        }
      });

      return prev;
    }, {});
  }

  static makeTree(options: {
    q: any[];
    id?: any;
    parentid: string;
    children?: string;
    sort?: boolean;
    sortProp?: string;
  }) {
    let children, e, id, list, pid, temp, _i, _len, _ref;
    id = options.id || "id";
    pid = options.parentid || "parentid";
    children = options.children || "children";
    temp = {};
    list = [];
    _ref = options.q;
    const sortTree = (tree) => {
      if (options.sort) {
        tree.sort(function (a, b) {
          return parseInt(a[options.sortProp]) - parseInt(b[options.sortProp]);
        });
      }
    };

    const foundTreeNode = (node, treeList) => {
      return treeList.find((itemNode) => {
        if (itemNode.id == node.id) {
          return true;
        } else {
          if (itemNode.hasOwnProperty("children")) {
            return foundTreeNode(itemNode, itemNode.children);
          }
          return false;
        }
      });
    };

    const createParentNode = (item) => {
      const matchedParent = _ref.find((itemNode) => {
        const parentD001 = itemNode.D001.toString();
        const selectedItemD001 = item.D001.toString().substr(0, 3);
        if (itemNode.id != item.id) {
          return parentD001 == selectedItemD001;
        }
      });
      if (matchedParent) {
        const existedParentNode = foundTreeNode(matchedParent, list);
        if (!existedParentNode) {
          if (!matchedParent.hasOwnProperty("children")) {
            matchedParent.children = [];
          }
          list.push(matchedParent);
        }
        return matchedParent;
      } else {
        return false;
      }
    };

    const getParentNode = (item) => {
      if (!item.hasOwnProperty("displaygroup_set")) {
        return createParentNode(item);
      } else {
        return createParentNode(item);
        /* if (item.D001 == 100310) {
          const x = createParentNode(item);
          return x;
        } */
      }
    };

    const sortNestedTree = () => {
      list.forEach((item) => {
        if (Array.isArray(item.children) && item.children.length) {
          sortTree(item.children);
        }
        return item;
      });
      sortTree(list);
    };

    _ref.forEach((element) => {
      let matchedParent = getParentNode(element);

      if (matchedParent) {
        if (matchedParent.id != element.id) {
          if (!matchedParent.hasOwnProperty("children")) {
            matchedParent.children = [];
          }
          matchedParent.children.push(element);
        }
      } else {
        const foundedTreeNode = foundTreeNode(element, list);
        if (!foundedTreeNode) {
          list.push(element);
        }
      }
    });

    sortNestedTree();
    //console.log("list", list);
    return list;
  }

  static listDiff(prevList, newList) {
    prevList = JSON.parse(JSON.stringify(prevList));
    newList = JSON.parse(JSON.stringify(newList));

    let changed = newList.filter(function (p, idx) {
      if (p["id"] !== prevList[idx]["id"]) {
        p["newSortOrder"] = idx + 1;
        return p;
      }
    });

    return changed;
  }

  static stripTextAsArgs = (str, params) => {
    const stripedText = str.replace(/{([^\{\}]+)}/g, (match, key) => {
      return Utils.getPropByString(params, key) !== undefined
        ? Utils.getPropByString(params, key)
        : "";
    });
    return stripedText;
  };

  static getPropByString = (obj, propString) => {
    if (!propString) return obj;

    let prop,
      props = propString.split(".");

    for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
      prop = props[i];

      var candidate = obj[prop];
      if (candidate !== undefined) {
        obj = candidate;
      } else {
        break;
      }
    }
    return obj[props[i]];
  };

  static setPropByPath(obj, prop, value) {
    if (typeof prop === "string") prop = prop.split(".");

    if (prop.length > 1) {
      var e = prop.shift();
      Utils.setPropByPath(
        (obj[e] =
          Object.prototype.toString.call(obj[e]) === "[object Object]"
            ? obj[e]
            : {}),
        prop,
        value
      );
    } else {
      obj[prop[0]] = value;
    }
    return obj;
  }

  static removePropByPath(obj, path) {
    if (!obj || !path) {
      return;
    }
    if (typeof path === "string") {
      path = path.split(".");
    }
    for (var i = 0; i < path.length - 1; i++) {
      obj = obj[path[i]];
      if (typeof obj === "undefined") {
        return;
      }
    }

    delete obj[path.pop()];
  }

  static serializeQuery(params, prefix = "") {
    const query = Object.keys(params)
      .filter((key) => params[key] != null && params[key] !== "")
      .map((key) => {
        const value = params[key];

        if (params.constructor === Array) key = `${prefix}[]`;
        else if (params.constructor === Object)
          key = prefix ? `${prefix}[${key}]` : key;

        if (typeof value === "object") return Utils.serializeQuery(value, key);
        else return `${key}=${value}`;
      });

    return [].concat.apply([], query).join("&");
  }

  static cloneObj(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static stripNaN(txt: any) {
    return Number(txt.toString().replace(/[^0-9]/g, ""));
  }

  static toNumber(val, trimSeparator = true) {
    if (isEmptyVal(val)) {
      return null;
    }
    const trimExtraSeparator = (input) => {
      var n = 0;
      return input.replace(/\./g, () => (n++ > 0 ? "" : "."));
    };

    val = trimExtraSeparator(val.toString().replace(/,/g, "."));

    if (trimSeparator == false) {
      if (val.length) {
        const strLength = val.length;

        if (val[0] == "." || val[strLength - 1] == ".") {
          return val;
        }
      }
    }

    if (isNumeric(val)) {
      return parseFloat(val);
    }
    return 0;
  }

  static toDateParse(val, format: string | string[] = "YYYY-MM-DD") {
    if (Array.isArray(format)) {
      return moment(val, [...format, "YYYY-MM-DD"]);
    }
    return moment(val, format);
  }

  static stripEmptyVal = (params) => {
    const validParams = {};
    Object.keys(params)
      .filter((key) => {
        if (params[key] != null && params[key] !== "") {
          if (Array.isArray(params[key])) {
            if (params[key].length > 0) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }
        return false;
      })
      .forEach((key) => {
        const value = params[key];
        validParams[key] = value;
      });

    return validParams;
  };

  static toClearForm(form: UntypedFormGroup) {
    form.reset();
    Object.keys(form.controls).forEach((key) => {
      const control = form.controls[key];
      control.setErrors(null);
    });
  }

  static parseDateFormat(str, format, defaultFormat = null) {
    defaultFormat = defaultFormat || ["YYYY-MM-DD"];
    return moment(str, defaultFormat).format(format);
  }

  static toDateInstance(val) {
    return moment(val);
  }

  static uniqueListByKey(list: any[], key: string | string[]) {
    const isPropValuesEqual = (subject, target, propNames) =>
      propNames.every((propName) => subject[propName] === target[propName]);

    const propNamesArray = Array.isArray(key) ? key : [key];

    return list.filter(
      (item, index, array) =>
        index ===
        array.findIndex((foundItem) =>
          isPropValuesEqual(foundItem, item, propNamesArray)
        )
    );
  }

  static numberFormat(
    val,
    opt: Partial<{ decimal: number; separator: string }> = null
  ): string {
    const defaultOpt = { decimal: 2, separator: "." };
    const separator = opt?.separator || defaultOpt.separator;
    const decimal = opt?.decimal || defaultOpt.decimal;
    let result: string;

    if (isEmptyVal(val)) {
      return null;
    }

    val = Number(val.toString().replace(/\,/g, "."));

    if (isNaN(val)) val = 0;

    result = Number(val).toFixed(decimal).toString().replace(/\./g, separator);

    return result;
  }

  static getUniqueListBy(arr: any[], key: string) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  static flattenArray(arr: any[]) {
    const flattenedArray = [];

    function recursiveFlatten(inputArr) {
      for (const item of inputArr) {
        if (Array.isArray(item)) {
          recursiveFlatten(item);
        } else {
          flattenedArray.push(item);
        }
      }
    }

    recursiveFlatten(arr);

    return flattenedArray;
  }

  static hasNonEmptyValues = (data) => {
    if (Array.isArray(data)) {
      // Check for array of objects
      for (let i = 0; i < data.length; i++) {
        if (Utils.hasNonEmptyValues(data[i])) {
          return true;
        }
      }
    } else if (typeof data === "object" && data !== null) {
      // Check for a single object
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          if (Utils.hasNonEmptyValues(data[key])) {
            return true;
          }
        }
      }
    } else {
      // Check for non-empty values
      return data !== null && data !== undefined && data !== "";
    }

    return false;
  };

  static flattenArrayByKey(arr, key) {
    const hasKey = arr.some((item) => item[key] && Array.isArray(item[key]));

    if (hasKey) {
      return arr.flatMap((item) =>
        item[key] && Array.isArray(item[key]) ? item[key] : [item]
      );
    } else {
      return arr;
    }
  }

  static flattenList(arr) {
    let flattenedArray = [];

    arr.forEach((element) => {
      if (Array.isArray(element)) {
        flattenedArray.push(...this.flattenList(element));
      } else {
        flattenedArray.push(element);
      }
    });

    return flattenedArray;
  }

  static countWords(inputString) {
    if (!inputString) {
      return 0;
    }

    let parts = inputString.split(" ");

    let cleanedParts = parts.filter((part) => part !== "");

    return cleanedParts.length;
  }
}
