import { Component } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { JwtService } from "./core/services/jwt.service";
import { CacheService } from "./core/services/cache.service";
import { AuthService, LocaleService } from "./core/services";
import { IdleService } from "./core/services/idle.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "storeverse backoffice";
  private destroyed$ = new Subject<void>();

  constructor(
    private cacheService: CacheService,
    private jwtService: JwtService,
    private localeService: LocaleService,
    private idleService: IdleService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.cacheService
      .observeOn(this.jwtService.sessionKey)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((_value) => {
        if (
          !this.jwtService.session &&
          this.authService.getUserSubject().value
        ) {
          this.authService.logout();
        }
      });

    this.localeService.setDefaultLang(this.authService.authUser?.pref?.last_preferred_lang);
    this.idleService.disposeWatcherWhenTimeout();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
