import { Directive, Host, HostListener, Input, Optional } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup, NgForm } from "@angular/forms";

@Directive({
  selector: "[svMarkFormTouched]",
})
export class MarkFormTouchedDirective {
  @Input() markOnClick: boolean = false;

  constructor(@Host() @Optional() private form: NgForm) {
    if (form && !this.markOnClick) {
      const oldSubmit = form.onSubmit;

      form.onSubmit = function (): boolean {
        if (form.invalid) {
          const controls = form.controls;
          Object.keys(controls).forEach((controlName) =>
            controls[controlName].markAsTouched()
          );
          return false;
        }
        return oldSubmit.apply(form, arguments);
      };
    }
  }

  @HostListener("click")
  onSubmit() {
    if (this.markOnClick) {
      const formGroup = this.form.form as UntypedFormGroup;

      formGroup.markAllAsTouched();
      this.markControlsDirty(formGroup);
    }
  }

  private markControlsDirty(group: UntypedFormGroup | UntypedFormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];

      if (
        abstractControl instanceof UntypedFormGroup ||
        abstractControl instanceof UntypedFormArray
      ) {
        this.markControlsDirty(abstractControl);
      } else {
        abstractControl.markAsDirty();
        abstractControl.updateValueAndValidity();
      }
    });
  }
}
