import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { Router, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { catchError, map, switchMap } from "rxjs/operators";

import { AuthService } from "./auth.service";
import { JwtService } from "./jwt.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService  {
  constructor(
    private authService: AuthService,
    private router: Router,
    private jwtService: JwtService,
    private notifyService: ToastrService
  ) {}

  /**
   * canActivate method is to verify
   * if navigate or not
   * @returns {boolean}
   * @memberof AuthGuardService
   */
  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | boolean
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | UrlTree {
    if (this.jwtService.getToken()) {
      if (this.authService.authUser) {
        if (
          this.jwtService.getToken() ==
          this.authService.authUser.session.access_token
        ) {
          return true;
        } else {
          return this.authorizeUser();
        }
      } else {
        return this.authorizeUser();
      }
    } else {
      /* this.notifyService.error(
        "You are unable to access the requested page. Please login",
        "Unauthorized"
      ); */
      this.router.navigate(["/"]);
      return false;
    }
  }

  authorizeUser(): Observable<boolean> {
    return this.authService.authenticateFirebaseUser().pipe(
      switchMap((res) => {
        if (res) {
          return this.authService.authenticateUser();
        }
      }),
      map((res) => {
        this.authService.setIdentity(res);
        return true;
      }),
      catchError((err) => {
        /* if (
          [
            HttpRespone.UNAUTHORIZED,
            HttpRespone.UNAUTHENTICATED,
            HttpRespone.INTERNAL_SERVER,
            HttpRespone.GATEWAY_TIMEOUT,
            HttpRespone.SERVICE_UNAVAILABLE,
          ].indexOf(err.status) != -1
        ) {
          this.authService.logout();
          return of(false);
        } */
        console.error(err);
        this.authService.logout();
        return of(false);
      })
    );
  }
}
