import { takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "src/app/core/services";
import { DashboardService } from "src/app/commons/services";
import { DocumentRefService } from "../utils";
import { IAuthUserSession } from "src/app/core/ITypes";

@Component({
  selector: "sv-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  providers: [DocumentRefService],
})
export class NavbarComponent implements OnInit {
  // defaults
  isActionDropdownVisible = false;
  username: string = "Welcome";
  storeName: string = null;
  storeUrl: string = null;
  authUser$: Observable<IAuthUserSession>;
  authUser;
  isNavbarCollapsed = true;
  destroyed$ = new Subject<void>();

  constructor(
    private route: Router,
    private authService: AuthService,
    private document: DocumentRefService,
    private dashboardService: DashboardService
  ) {
    this.authUser = this.authService.authUser;
    this.authUser$ = this.authService.authUser$;

    if (this.authUser) {
      this.username = this.authUser.name;
      this.storeName = this.authUser.store.label;
      this.storeUrl = this.authUser.storeUrl;
    }

    this.dashboardService.sidebarSubscriber$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((val) => {
        if (val) {
          this.toggleSideMenu();
        }
      });
  }

  ngOnInit(): void {}

  toggleActionDropdown() {
    this.isActionDropdownVisible = !this.isActionDropdownVisible;
  }

  handleLogout() {
    this.authService.logout();
    this.route.navigate(["/"]);
    this.closeNavbar();
  }

  toggleNavbar(opt = null) {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  closeNavbar() {
    this.isNavbarCollapsed = true;
    this.isActionDropdownVisible = false;
  }

  toggleSideMenu() {
    const layoutElement = this.document
      .get()
      .querySelector("sv-layout .layout");
    const sideBarElement = this.document.get().querySelector("#sidebar");
    const sideMenuIconElement = this.document
      .get()
      .querySelector(".nav-mobile-menu-btn.side-menu-btn");

    if (sideBarElement) {
      if (sideBarElement.classList.contains("active")) {
        sideBarElement.classList.remove("active");
        layoutElement.classList.remove("side-menu--open");
        sideMenuIconElement.classList.remove("open");
      } else {
        sideBarElement.classList.add("active");
        layoutElement.classList.add("side-menu--open");
        sideMenuIconElement.classList.add("open");
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
