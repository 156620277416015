import { Component, Input, OnInit, forwardRef, EventEmitter, Output, Directive } from "@angular/core";

import { ToastrService } from "ngx-toastr";

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { DisplayGroupService } from "src/app/commons/services";
import { IDisplayGroup } from "src/app/commons/ITypes";
import { IQueryParams } from "src/app/core/ITypes";
import { DisplayGroupStatus } from "src/app/layout/display-group/ITypes";
import { SiteConfigService } from "src/app/core/services";
import { LocaleService } from "src/app/core/services";

@Directive()
class BaseModel {
  /**
   * Holds the current value of the slider
   */
  value: any;

  ngOnInit() {}

  /**
   * Invoked when the model has been changed
   */
  onChange: (_: any) => void = (_: any) => {};

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {};

  constructor() {}

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges() {
    this.onChange(this.value);
  }

  ///////////////
  // OVERRIDES //
  ///////////////

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value: number): void {
    this.value = value;
    this.updateChanges();
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}

@Component({
  selector: "sv-display-group-dropdown",
  templateUrl: "./display-group-dropdown.component.html",
  styleUrls: ["./display-group-dropdown.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DisplayGroupDropdownComponent),
      multi: true,
    },
  ],
})
export class DisplayGroupDropdownComponent
  extends BaseModel
  implements OnInit, ControlValueAccessor
{
  displayGroups: IDisplayGroup[] = [];
  displayGroupStatus = DisplayGroupStatus;
  hasHirerachy = true;

  @Input() queryParams: IQueryParams;
  @Input() key: string;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() name: string;
  @Input() hideArchived: boolean = true;
  @Input() appendTo: string;
  @Input() size = "md";
  @Input() placeholder = "Display Group";
  @Input() cached = false;
  @Input() defaultOptions;

  readonly defaultPlaceholder = "Display Group";

  @Output("change") changeEvent = new EventEmitter();

  constructor(
    private toastr: ToastrService,
    private displaygroupService: DisplayGroupService,
    private siteConfigService: SiteConfigService,
    private localeService: LocaleService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.defaultOptions) {
      this.getDisplayGroups();
    }

    if (this.siteConfigService.getItem("displaygroup.hierarchyLevel") == 1) {
      this.hasHirerachy = false;
    }
  }

  async getDisplayGroups() {
    try {
      this.displayGroups = await this.displaygroupService.getDisplayGroups({
        hierarchy: true,
        cached: this.cached,
      });

      if (this.hideArchived) {
        this.displayGroups = this.displayGroups.filter(
          (item: IDisplayGroup) => {
            return item.active != DisplayGroupStatus.ARCHIVE;
          }
        );
      }
    } catch (error) {
      this.toastr.error("Internal server error occurred", "Display Groups");
    }
  }

  groupByDisplayGroupSet = (item) => item.displaygroup_set_name;

  groupByDisplayGroupSetValue = (_: string, children: any[]) => {
    return {
      displaygroup_set_name: children[0].displaygroup_set_name,
      displaygroup_set_active: children[0].displaygroup_set_active,
    };
  };

  compareDisplayGroupValue(item, selected) {
    return item?.D001 == selected;
  }

  handleChange() {
    this.changeEvent.emit(this.value);
  }

  onSearch = (term: string, item) => {
    const pattern = new RegExp(term, "gi");
    const lang = this.localeService.selectedLang;
    const isTitleMatched = pattern.test(item.lang[lang]);
    const isCodeMatched = pattern.test(item.D001);

    return isTitleMatched || isCodeMatched;
  };
}
