import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Utils } from "src/app/commons/utils";
import { BREADCRUMB_OPTION } from "../ITypes";
import { LocaleService } from "src/app/core/services";
import { Subscription } from "rxjs";
import { BreadcrumbService } from "xng-breadcrumb";

@Injectable()
export class NavigatorService {
  private breadcrumLangSubscription: Subscription;

  constructor(
    private breadcrumService: BreadcrumbService,
    private route: ActivatedRoute,
    private router: Router,
    private localeService: LocaleService
  ) {}

  changeBreadcrumb(name: string, opt: BREADCRUMB_OPTION = null) {
    const keyName = name;

    if (opt && opt.param) {
      if (opt.translate) {
        name = this.localeService.trans(name);
      }

      name = Utils.stripTextAsArgs(name, opt.param);
    }

    let mode = this.route.snapshot.queryParams["mode"];

    if (mode === "debug") {
      name = `{{${keyName}}} ${name}`;
    }

    if (opt.routeAlias) {
      const aliasUrl = `@${opt.routeAlias}`;

      this.breadcrumService.set(aliasUrl, name);
      return;
    }

    const aliasUrl = this.route.snapshot.routeConfig?.data?.breadcrumb?.alias;

    if (opt && aliasUrl) {
      const urlSlug = `@${aliasUrl}`;

      this.breadcrumService.set(urlSlug, name);
    } else {
      // Option 2: Constructing the full path from the activated route tree

      const fullRouteTemplate = this.getRouteTemplate(this.route.snapshot);

      this.breadcrumService.set(fullRouteTemplate, name);
    }

    /* if (opt && opt.parent) {
      this.breadcrumService.set(this.route.parent.snapshot, name);
    } else {
      this.breadcrumService.set(this.route.snapshot, name);
    } */
  }

  subcribeLocaleBreadcrum(name: string, opt: BREADCRUMB_OPTION = null) {
    if (this.breadcrumLangSubscription) {
      this.breadcrumLangSubscription.unsubscribe();
    }

    this.changeBreadcrumb(name, opt);

    this.breadcrumLangSubscription = this.localeService
      .onTranslationChange()
      .subscribe((res) => {
        this.changeBreadcrumb(name, opt);
      });
  }

  unsubcribe() {
    if (this.breadcrumLangSubscription) {
      this.breadcrumLangSubscription.unsubscribe();
    }
  }

  getRouteTemplate(routeSnapshot: any): string {
    let path = "";

    // Traverse parent routes
    let currentRoute = routeSnapshot;
    while (currentRoute.parent) {
      currentRoute = currentRoute.parent;
      if (currentRoute.routeConfig && currentRoute.routeConfig.path) {
        path = `/${currentRoute.routeConfig.path}` + path;
      }
    }

    // Traverse child routes (current route and its children)
    let childRoute = routeSnapshot;
    while (childRoute) {
      if (childRoute.routeConfig && childRoute.routeConfig.path) {
        path += `/${childRoute.routeConfig.path}`;
      }
      childRoute = childRoute.firstChild;
    }

    return path;
  }
}
