import * as moment from "moment";

export class Calendar {
  static formatType = {
    hour24: "HH:mm:ss",
  };

  static parse(val, fromFormat = null) {
    return moment(val, fromFormat);
  }

  static format(val = null, format = null) {
    return moment(val || moment()).format(format || "YYYY-MM-DD");
  }

  static getNextDateByhours(daysInHours: number) {
    const duration = daysInHours * 60 * 60; // days * 24 * 60 * 60
    const durationInMilliseconds = duration * 1000;
    return new Date().getTime() + durationInMilliseconds;
  }

  static getRemainingTime(sourceDate: number, targetDate: number = null) {
    const sourceDateInstance = moment(sourceDate);
    const targetDateInstance = targetDate ? moment(targetDate) : moment();

    return moment
      .duration(sourceDateInstance.diff(targetDateInstance))
      .asMilliseconds();
  }

  static getDayByNumberOffset(num: number) {
    const firstDayOfWeek = moment().startOf("isoWeek");
    const desiredDay = firstDayOfWeek.add(num - 1, "days");
    const dayName = desiredDay.format("dddd");

    return dayName;
  }

  static toTime(val: string) {
    if (!val) {
      return {
        time: null,
        value: null,
      };
    }

    const parsedTime = moment(val, ["HH:mm:ss"]);
    const date = moment();

    date.set({
      hour: parsedTime.get("hour") || 0,
      minute: parsedTime.get("minute") || 0,
      second: parsedTime.get("second") || 0,
    });

    return { time: date, value: date.format("HH:mm:ss") };
  }

  static getDaysDuration(inputDate: string) {
    const inputDateInstance = moment(inputDate);
    const currentDateInstance = moment();

    return currentDateInstance.diff(inputDateInstance, "days");
  }
}
