import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { AuthService, LocaleService } from "src/app/core/services";
import { LocaleCode } from "src/app/config";
import { UserService } from "../../services";

@Component({
  selector: "sv-lang-switcher",
  templateUrl: "./lang-switcher.component.html",
  styleUrls: ["./lang-switcher.component.scss"],
})
export class LangSwitcherComponent implements OnInit {
  selectedLang: LocaleCode;
  langOptions = [];
  private destroyed$ = new Subject<void>();

  constructor(
    private localeService: LocaleService,
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.langOptions = this.localeService.getAvailableLocales();

    this.localeService
      .getLanguageStream()
      .pipe(
        filter((res) => res !== this.selectedLang),
        takeUntil(this.destroyed$)
      )
      .subscribe((res) => {
        this.selectedLang = res as LocaleCode;
      });
  }

  async changeLanguage() {
    this.localeService.setPreferredLang(this.selectedLang);

    if (!this.authService.authUser.pref) {

      const _userPref = await this.userService.postUserPref(
        {
          last_preferred_lang: this.selectedLang,
          user_uuid: this.authService.authUser.uuid,
        },
      );

      this.authService.setUserPref(_userPref);
    } else {
      this.userService.updateUserPref(
        {
          last_preferred_lang: this.selectedLang,
          user_uuid: this.authService.authUser.uuid,
        },
        this.authService.authUser.pref.id
      );
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
