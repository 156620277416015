import { Injectable } from "@angular/core";

import {
  ApiService,
  HttpQueryService,
  LocaleService,
} from "src/app/core/services";
import { GeneralService } from "./general.service";

import {
  DisplayGroupCategory,
  DisplayGroupStatus,
} from "src/app/layout/display-group/ITypes";
import { displayGroupFormSchema } from "src/app/layout/display-group/item/schema";

import { IDataResponse } from "src/app/core/ITypes";
import { IDisplayGroup, SchemaOptionValue } from "../ITypes";

import { Utils } from "src/app/commons/utils";
import { AppConstant } from "src/app/commons/app.constant";

@Injectable({
  providedIn: "root",
})
export class DisplayGroupService {
  private maxCodeLength = 6;
  private browserLang: string = "en";

  constructor(
    private api: ApiService,
    private httpQueryService: HttpQueryService,
    private generalService: GeneralService,
    private localeService: LocaleService
  ) {}

  async getDisplayGroups(
    options: { hierarchy: boolean; cached?: boolean } = { hierarchy: false }
  ) {
    try {
      let response;

      if (options?.cached === false) {
        response = await this.api
          .get("/display-group?order=C001&direction=asc&limit=500")
          .toPromise();
      } else {
        response = await this.api
          .getByCached("/display-group?order=C001&direction=asc&limit=500")
          .toPromise();
      }

      const { status, result } = response;
      const displayGroupSet = await this.getDisplayGroupSet({ cached: false });

      if (status === 200 && result) {
        const displayGroups = result
          .filter((item) => {
            /* AppConstant.hidePandaDisplayGroup.indexOf(
                item.properties.D001
              ) === -1; */
            if (!item.properties.D001) {
              return false;
            }
            return item;
          })
          .map((r) => {
            const props = r.properties;
            //props.name = props.name[this.browserLang];
            // It's for now hardcoded because we have not available all english names for display groups
            props.lang = { ...props.name };
            props.name = props.name[this.browserLang];
            props.id = r.id;
            return props;
          });

        if (options.hierarchy) {
          return Utils.makeTree({
            q: [...displayGroupSet, ...displayGroups],
            parentid: "displaygroup_set",
            id: "D001",
            sort: true,
            sortProp: "order",
          });
        } else {
          return this.categorizeDisplayGroupBySet(
            displayGroups,
            displayGroupSet
          );
        }
      }
    } catch (error) {
      throw error;
    }
  }

  async getActiveDisplayGroups() {
    try {
      const displayGroups = await this.getDisplayGroups({
        hierarchy: true,
        cached: false,
      });

      return displayGroups.filter((item: IDisplayGroup) => {
        return item.active != DisplayGroupStatus.ARCHIVE;
      });
    } catch (error) {
      throw error;
    }
  }

  async getDisplayGroupSet(opt = null) {
    try {
      let response;

      if (opt?.cached === false) {
        response = await this.api
          .get("/display-group-set?limit=500&direction=asc&order=C001")
          .toPromise();
      } else {
        response = await this.api
          .getByCached("/display-group-set?limit=500&direction=asc&order=C001")
          .toPromise();
      }

      const { status, result } = response;

      if (status === 200 && result) {
        const groupSet = result.map((r) => {
          const props = r.properties;
          //props.name = props.name[this.browserLang];
          // It's for now hardcoded because we have not available all english names for display groups
          props.lang = { ...props.name };
          props.name = props.name[this.browserLang];
          props.id = r.id;
          props.D001 = (props.D001 && parseInt(props.D001)) || props.D001;
          return props;
        });
        if (opt && opt.sort) {
          groupSet.sort((a, b) => {
            return a.order - b.order;
          });
        }
        return groupSet;
      }
    } catch (error) {
      throw error;
    }
  }

  private categorizeDisplayGroupBySet(displayGroups, displayGroupSet) {
    return displayGroups
      .map((item) => {
        const foundGroupSet = displayGroupSet.find(
          (groupSet) => groupSet.D001 == item.displaygroup_set
        );
        const foundGroupLevel = displayGroups.find(
          (group) => group.D001 == item.displaygroup_set
        );
        if (foundGroupSet) {
          item.displaygroup_set_name = foundGroupSet.name;
          item.displaygroup_set_active = foundGroupSet.active;
        } else if (!foundGroupSet && foundGroupLevel) {
          item.displaygroup_set_active = foundGroupLevel.active;
        }
        return item;
      })
      .filter((item) => {
        return this.isDisplayGroupSet(item.D001) ? false : true;
      });
  }

  deleteDisplayGroup(id, category: DisplayGroupCategory): Promise<any> {
    /* const body = new HttpParams({ fromObject: payload });
    const authHeaders = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }); */
    return this.api.delete(`/${category}/${id}`).toPromise();
  }

  async getDetails(id: string) {
    try {
      const { status, result } = await this.api
        .getById(null, `/${id}`)
        .toPromise();

      if (status === 200 && result) {
        const detail = { id: result.id, ...result.properties };
        return detail;
      }
    } catch (error) {
      throw error;
    }
  }

  async patchDisplayGroup(
    payload,
    category: DisplayGroupCategory
  ): Promise<any> {
    try {
      const categoryName =
        category == DisplayGroupCategory.DISPLAY_GROUP
          ? "Display Group"
          : "Display Group Set";
      if (Array.isArray(payload)) {
        payload = payload.map((item) => {
          const payloadItem = { ...item };
          delete payloadItem.id;

          return {
            properties: payloadItem,
            id: item.id,
          };
        });
      }

      const response = await this.api
        .patch(payload, `/${category}`)
        .toPromise();
      const { status, result } = response;
      if (status === 200) {
        const props = result;
        return {
          data: props,
          message: this.localeService.trans("NOTIFY.NT007", {
            categoryName: categoryName,
          }),
          status: true,
        };
      } else {
        return {
          props: [],
          message: "Something went wrong",
          status: false,
        };
      }
    } catch (error) {
      throw error;
    }
  }

  async postDisplayGroup(data, category: DisplayGroupCategory, id = null) {
    try {
      const payload = {
        properties: data,
      };
      let requestUrl, response, responseMsg, orderNumber;

      if (
        // category == DisplayGroupCategory.DISPLAY_GROUP_SET &&
        data.order == null
      ) {
        if (category == DisplayGroupCategory.DISPLAY_GROUP) {
          if (data.displaygroup_set) {
            orderNumber = await this.fetchDisplayGroupsetLatestSortNumber(
              category,
              {
                filter: [
                  {
                    key: "displaygroup_set",
                    value: data.displaygroup_set,
                  },
                ],
              }
            );
            payload.properties.order = Number(
              data.displaygroup_set.toString() + orderNumber
            );
          }
        } else if (category == DisplayGroupCategory.DISPLAY_GROUP_SET) {
          orderNumber = await this.fetchDisplayGroupsetLatestSortNumber(
            category
          );
          payload.properties.order = orderNumber;
        }
      }

      if (id) {
        requestUrl = `/${category}`;
        payload["id"] = id;
        response = await this.api.put(payload, requestUrl).toPromise();
        responseMsg = this.localeService.trans("NOTIFY.NT005");
      } else {
        requestUrl = `/${category}`;
        response = await this.api.post(payload, requestUrl).toPromise();
        responseMsg = this.localeService.trans("NOTIFY.NT006");
      }

      const { status, result } = response;

      if (status === 200 && result) {
        const props = result.properties;
        props["id"] = result.id;
        return {
          data: props,
          message: responseMsg,
        };
      }
    } catch (error) {
      throw error;
    }
  }

  extractDisplaygroupVal(val) {
    if (val) {
      return val.toString().substr(3, 3);
    }
    return "";
  }

  isDisplayGroupSet(val) {
    if (val) {
      return val.toString().length == 3 ? true : false;
    }
    return false;
  }

  identifyDisplayGroupVal(D001, displayGroupSet) {
    if (D001 && displayGroupSet) {
      return this.extractDisplaygroupVal(D001);
    } else if (D001) {
      D001 = D001.toString();
      return D001;
    } else {
      return;
    }
  }

  identifyDisplayGroupRole(D001, displayGroupSet) {
    // 1== Display Group
    // 2== Display Group Set
    if (displayGroupSet) {
      return 1;
    } else if (D001) {
      D001 = D001.toString();
      return D001.length == 3 ? 2 : 1;
    } else {
      return;
    }
  }

  getFullDisplayGroupCode(D001, displayGroupSet) {
    if (D001) {
      D001 = D001.toString();
    }
    if (displayGroupSet) {
      displayGroupSet = displayGroupSet.toString();
      D001 = displayGroupSet + D001;
      return parseInt(D001);
    } else {
      return parseInt(D001);
    }
  }

  replaceDisplayGroupCode(D001, displaygroup_set) {
    const code = this.extractByCode(D001);
    displaygroup_set = displaygroup_set.toString();

    return displaygroup_set + code.D001;
  }

  extractByCode(D001): { D001: number; displaygroup_set: number } {
    if (D001) {
      D001 = D001.toString();
      if (D001.length == 6) {
        return {
          D001: parseInt(D001.substr(3, 3)),
          displaygroup_set: parseInt(D001.substr(0, 3)),
        };
      } else {
        return {
          D001: parseInt(D001),
          displaygroup_set: null,
        };
      }
    }
    return;
  }

  findMaxCodeLength(val) {
    val = val.toString();
    return val.length == this.maxCodeLength;
  }

  getCategoryName(val) {
    return this.isDisplayGroupSet(val) ? "Display Group Set" : "Display Group";
  }

  getCategory(val) {
    //return DisplayGroupCategory.DISPLAY_GROUP;
    return this.isDisplayGroupSet(val)
      ? DisplayGroupCategory.DISPLAY_GROUP_SET
      : DisplayGroupCategory.DISPLAY_GROUP;
  }

  async fetchDisplayGroupsetLatestSortNumber(
    reqType: DisplayGroupCategory,
    params = {}
  ) {
    try {
      const queryString = this.httpQueryService.serialize(
        Object.assign(
          {},
          {
            limit: 1,
            skip: 0,
            direction: "desc",
            order: "order",
          },
          params
        )
      );

      const { status, result, meta } = await this.api
        .get(`/${reqType}?${queryString}`)
        .toPromise();

      if (status === 200 && result) {
        if (meta.total) {
          return meta.total + 1;
        }

        if (Array.isArray(result) && result.length) {
          if (result[0].properties.hasOwnProperty("order")) {
            const sortOrder = result[0].properties.order;
            return sortOrder + 1;
          } else {
            if (meta.total) {
              return meta.total + 1;
            }
            return null;
          }
        } else {
          return 1;
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async isCodeExist(reqUrl, params): Promise<IDataResponse> {
    try {
      let query = Object.assign({}, params.query, { limit: 1 });

      let recordFound = false;
      let msgLiteral = "";

      const { result } = await this.getAllDisplayGroup(reqUrl, query);

      if (result.length) {
        recordFound = result.length > 0;
        msgLiteral = this.localeService.trans("ERRORS.ER006");

        if (recordFound) {
          let props = result[0];

          if (props.id == params.id) {
            return { status: false, msg: "" };
          }

          msgLiteral = msgLiteral.replace(
            "{msg}",
            `code  : <strong>${props.D001}</strong>`
          );
        }
      }
      return {
        status: recordFound,
        msg: msgLiteral,
        msgTitle: (msgLiteral && "Duplicate Code") || "",
      };
    } catch (error) {
      throw error;
    }
  }

  async getAllDisplayGroup(reqUrl, params) {
    try {
      const queryString = this.httpQueryService.serialize(params);
      const apiEndpoint = `/${reqUrl}?${queryString}`;

      const { status, result, meta } = await this.api
        .get(apiEndpoint)
        .toPromise();

      let data = [];

      if (status === 200 && result) {
        for (let i = 0; i < result.length; i++) {
          const res = result[i];
          let props = res.properties;

          data.push({
            ...props,
            id: res.id,
          });
        }
      }
      return { result: data, meta };
    } catch (error) {
      throw error;
    }
  }

  getFormSchema() {
    try {
      const { status, result } = displayGroupFormSchema;

      if (status === 200 && result && result.properties) {
        const schemaMapOption: SchemaOptionValue = {
          active: {
            options: AppConstant.displayGroupStatuses,
            defaultOptValue: 0,
          },
        };
        const components = result.properties.components;
        const panels: Array<any> = this.generalService.patchSchemaOptions(
          components,
          schemaMapOption
        );

        return panels;
      }
    } catch (error) {
      throw error;
    }
  }

  findDisplayGroupByKey(displayGroups = [], key: string) {
    return displayGroups.find((item) => {
      if (item.children) {
        return item.children?.length
          ? this.findDisplayGroupByKey(item.children, key)
          : [];
      } else {
        return item.D001 == key;
      }
    });
  }

  getDisplayGroupTitle(displayGroups = [], key) {
    const foundDisplaygroup = this.findDisplayGroupByKey(displayGroups, key);

    if (foundDisplaygroup) {
      return foundDisplaygroup.name;
    }

    return;
  }
}
