import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { Injectable } from "@angular/core";
import {
  IFormGroupSchema,
  ISchemaField,
  ISchemaFieldType,
  IFormGroupSchemaMeta,
  IBaseDateInput,
  SchemaInputType,
} from "../ITypes";
import { FormValidatorService } from "./form-validator.service";
import { BaseValidator } from "src/app/commons/utils/base-validator";
import { Utils, _deepClone } from "src/app/commons/utils";
import { ISchemaValidator } from "./../ITypes";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable()
export class FormSchemaService {
  formGroup: UntypedFormGroup;
  schema: ISchemaFieldType = {};
  schemaControls: ISchemaField[] = [];
  validatorSchema;

  // Subscriber-Emitter
  private _schemaCreatedEmitter: Subject<boolean> =
    new BehaviorSubject<boolean>(false);
  schemaCreated$: Observable<boolean> =
    this._schemaCreatedEmitter.asObservable();

  private _arrayAfterPushedEmitter: Subject<void> = new Subject<void>();
  schemaArrayControlAfterPushed$: Observable<void> =
    this._arrayAfterPushedEmitter.asObservable();

  private _arrayBeforePushedEmitter: Subject<void> = new Subject<void>();
  schemaArrayControlBeforePushed$: Observable<unknown> =
    this._arrayBeforePushedEmitter.asObservable();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formValidatorService: FormValidatorService,
    private authService: AuthService
  ) {}

  buildSchema(schemaControls: any[], validatorSchema = BaseValidator) {
    this.schemaControls = schemaControls;
    this.validatorSchema = validatorSchema;

    this.parseSchema(schemaControls);
    this.buildForm();
  }

  createSchema(
    schemaControls: any[],
    validatorSchema = BaseValidator,
    metaOpt: IFormGroupSchemaMeta = null
  ): IFormGroupSchema {
    this.buildSchema(schemaControls, validatorSchema);

    this._schemaCreatedEmitter.next(true);
    return {
      formGroup: this.formGroup,
      schema: this.schema,
      schemaControls: this.schemaControls,
      errorNotify: metaOpt && metaOpt.errorNotify,
      filteredSchema: this.filteredSchema,
      patchValue: this.patchData,
      patchFormArrayControl: this.patchFormArrayControl,
      setFieldValue: this.setFieldValue,
      setFieldOption: this.setFieldOption,
      updateFieldOption: this.updateFieldOption,
      hideField: this.hideField,
      setValidators: this.setValidators,
      resolveDataFromSchema: this.resolveDataFromSchema,
      schemaFieldCallback: this.schemaFieldCallback,
      removeField: this.removeField,
      validateForm: this.validateForm,
    };
  }

  private parseSchema(schemaControls: ISchemaField[]) {
    schemaControls.forEach((item: ISchemaField) => {
      if (item.type == "panel" || item.type == "inputGroup") {
        this.parseSchema(item.components);
      } else {
        this.parseSchemaField(item);
      }
    });
  }

  private parseSchemaField(item: ISchemaField) {
    this.schema[item.key] = item;

    // Adding validation
    this.schema[item.key].validators =
      (item.validators &&
        item.validators.map((validation) => {
          const callbackFnName = validation.callback;
          const validatorConfig = {
            ...validation,
            callback: this.validatorSchema[callbackFnName],
            callbackName: callbackFnName,
          };

          return validatorConfig;
        })) ||
      [];

    if (this.schema[item.key].required) {
      this.schema[item.key].validators.push({
        callback: BaseValidator.required,
      });
    }
  }

  private fetchFieldsByTenantId(_schemaControls: ISchemaField[] = []) {
    const hasVisibleField = (item: ISchemaField) => {
      if (
        Array.isArray(item.isVisibleTo) &&
        item.isVisibleTo.indexOf(this.authService.authUser.channelId) === -1
      ) {
        return null;
      }

      if (
        Array.isArray(item.isHiddenFrom) &&
        item.isHiddenFrom.indexOf(this.authService.authUser.channelId) !== -1
      ) {
        return null;
      }

      return item;
    };

    const extractField = (_schemaFields: Array<ISchemaField | null>) => {
      return _schemaFields.filter((item: ISchemaField) => {
        if (!item) {
          return false;
        }
        return hasVisibleField(item);
      });
    };

    const fieldAccessMapper = (_schemaFields: ISchemaField[]) => {
      return _schemaFields.map((item: ISchemaField) => {
        if (item.type == "panel" || item.type == "inputGroup") {
          item.components = extractField(fieldAccessMapper(item.components));
          return item;
        }

        return hasVisibleField(item);
      });
    };

    const parsedFields = _schemaControls.map((item: ISchemaField) => {
      if (item.type == "panel" || item.type == "inputGroup") {
        const panelCmpList = extractField(fieldAccessMapper(item.components));

        if (panelCmpList.length <= 0) {
          return null;
        }

        item.components = panelCmpList;
        return item;
      } else {
        if (hasVisibleField(item)) {
          return item;
        } else {
          return null;
        }
      }
    });

    return extractField(parsedFields);
  }

  private buildForm() {
    this.formGroup = this.formBuilder.group({});
    this.parseForm();
  }

  private parseForm() {
    Object.keys(this.schema).forEach((fieldKey) => {
      const schemaControl: ISchemaField = this.schema[fieldKey];
      const formControl = new UntypedFormControl(null);

      //For disabling the input
      if (
        [schemaControl.type].indexOf("select") != -1 &&
        schemaControl.disabled
      ) {
        formControl.disable();
      } else if ([schemaControl.type].indexOf("checkbox-group") != -1) {
        this.setFormArrayControlToSchema(schemaControl);
        return;
      } else if ([schemaControl.type].indexOf("form-array") != -1) {
        this.setFormArrayControl(schemaControl);
        return;
      }

      if (schemaControl.default != null && schemaControl.default != undefined) {
        formControl.setValue(schemaControl.default);
      }

      this.formGroup.addControl(fieldKey, formControl);

      this.formValidatorService.setValidations(
        schemaControl,
        formControl,
        this.formGroup
      );
    });
  }

  setFormArrayControlToSchema(schemafield: ISchemaField) {
    const arrayControl = new UntypedFormArray([]);

    schemafield.data.values.map((opt) => {
      let formControl;
      if (schemafield.multiSubField) {
        const nestedFormGroup = new UntypedFormGroup({});
        schemafield.multiSubField.keys.forEach((subField) => {
          const nestedFormControl = this.setFieldControl(schemafield);
          nestedFormGroup.addControl(subField.key, nestedFormControl);
        });
        if (schemafield.multiSubField.includeNamedKey) {
          nestedFormGroup.addControl(
            schemafield.multiSubField.includeNamedKey,
            new UntypedFormControl(opt.value)
          );
        }
        formControl = nestedFormGroup;
      } else {
        formControl = this.setFieldControl(schemafield);
      }
      arrayControl.push(formControl);
    });

    this.formGroup.addControl(schemafield.key, arrayControl);
  }

  setFormArrayControl(schemafield: ISchemaField) {
    const arrayControl = this.formBuilder.array([]);

    this.formGroup.addControl(schemafield.key, arrayControl);
  }

  setFieldControl(schemafield: ISchemaField): UntypedFormControl {
    const formControl = new UntypedFormControl();
    if (schemafield.default != null && schemafield.default != undefined) {
      formControl.setValue(schemafield.default);
    }
    return formControl;
  }

  valid() {
    return this.formGroup.valid;
  }

  patchData(value, opt = null) {
    this.formGroup.patchValue(value);

    const schemaIteratorCallback = (schemaControls: ISchemaField[]) => {
      schemaControls.forEach((schemaField: ISchemaField) => {
        if (schemaField.type == "panel") {
          schemaIteratorCallback(schemaField.components);
        } else if (["form-array"].indexOf(schemaField.type) != -1) {
          this.patchFormArrayControl(schemaField, value);
        }
      });
    };

    schemaIteratorCallback(this.schemaControls);

    if ((opt && opt.default) || (opt && opt.data && opt.data.defaultOptValue)) {
      const defaultDropdownValue =
        opt.default || (opt.data && opt.data.defaultOptValue) || null;

      this.schemaControls.forEach((schemaField) => {
        if (
          !value.hasOwnProperty(schemaField.key) &&
          schemaField.type == "select"
        ) {
          if (schemaField.hasOwnProperty("missingPropValue")) {
            const fieldControl = this.formGroup.get(schemaField.key);
            fieldControl.setValue(schemaField.missingPropValue);
          }
        }

        if (
          !value.hasOwnProperty(schemaField.key) &&
          ["dropdown"].indexOf(schemaField.type) != -1 &&
          defaultDropdownValue
        ) {
          const fieldControl = this.formGroup.get(schemaField.key);
          fieldControl.setValue(defaultDropdownValue);
        }
      });
    }

    if (opt && opt.patchByProp) {
      Object.keys(this.schema)
        .filter((schemaFieldKey) =>
          this.isExcludedField(schemaFieldKey) ? false : true
        )
        .forEach((schemaFieldKey) => {
          const schemaField = this.schema[schemaFieldKey];
          const formField = this.formGroup.get(schemaFieldKey);

          formField.setValue(
            Utils.getPropByString(value, schemaField.prop) || null
          );
        });
    }
  }

  patchFormArrayControl(schemaField: ISchemaField, formValue) {
    const formArray = this.formGroup.get(schemaField.key) as UntypedFormArray;
    const selectedControlValue: any[] = formValue[schemaField.key] || [];

    selectedControlValue.forEach((row, index) => {
      const formGroup = new UntypedFormGroup({});

      schemaField.components.forEach((cmpField) => {
        const formControl = new UntypedFormControl(row[cmpField.key] || null);
        formGroup.addControl(cmpField.key, formControl);
      });

      formArray.push(formGroup);
    });
  }

  setFieldValue(key: string, value: any) {
    this.formGroup.get(key).setValue(value);
  }

  setFieldOption(
    key: string,
    option: Partial<ISchemaField>,
    shouldPatch = false
  ) {
    const schemaField = this.schema[key];

    Object.keys(option).forEach((optKey) => {
      const value = option[optKey];

      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        Utils.setPropByPath(schemaField, optKey, {
          ...schemaField[optKey],
          ...option[optKey],
        });
      } else {
        Utils.setPropByPath(schemaField, optKey, option[optKey]);
      }
    });

    if (shouldPatch) {
      const patchedVal =
        schemaField.default ||
        (schemaField.data && schemaField.data.defaultOptValue) ||
        null;
      this.setFieldValue(key, patchedVal);
    }
  }

  getSchemaField(keyProp: string): ISchemaField {
    return Utils.resolveProp(this.schema, keyProp);
  }

  mergeSchemaFieldOption(
    schemaField: ISchemaField,
    option: Partial<ISchemaField>
  ) {
    Object.keys(option).forEach((optKey) => {
      const value = option[optKey];

      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        Utils.setPropByPath(schemaField, optKey, {
          ...schemaField[optKey],
          ...option[optKey],
        });
      } else {
        Utils.setPropByPath(schemaField, optKey, option[optKey]);
      }
    });
  }

  formSchemaIterator(callbackFn: (field: ISchemaField) => boolean) {
    const schemaIteratorCallback = (schemaControls: ISchemaField[]) => {
      schemaControls.every((field: ISchemaField) => {
        return callbackFn(field);
      });
    };

    return schemaIteratorCallback(this.schemaControls);
  }

  updateFieldOption(key: string, opt: { prop: string; value: any }) {
    const schemaField = this.schema[key];
    Utils.setPropByPath(schemaField, opt.prop, opt.value);
  }

  hideField(
    key: string,
    opt: { default?: any; hideValidation?: boolean } = null
  ) {
    this.setFieldOption(key, { hidden: true });

    if (!opt || (opt && opt.hideValidation === true)) {
      this.formGroup.get(key).clearValidators();
    }

    if (opt && opt.default) {
      this.setFieldValue(key, opt.default);
    }
  }

  isExcludedField(key) {
    return this.schema[key].ignore;
  }

  removeField(key: string | string[]) {
    const clearField = (_key) => {
      this.formGroup.get(_key).clearValidators();
      this.formGroup.get(_key).disable();
      this.setFieldOption(_key, { ignore: true });
    };
    if (Array.isArray(key)) {
      key.forEach((_key) => clearField(_key));
    } else {
      clearField(key);
    }
  }

  setValidators(
    key: string,
    validatorInput: ISchemaValidator | ISchemaValidator[]
  ) {
    const schemaField = this.schema[key];
    const formField = this.formGroup.get(key);
    const schemaValidators = schemaField.validators;
    let validators = validatorInput;

    if (!Array.isArray(validators)) {
      validators = [validators];
    }

    validators.forEach((item) => {
      const existValidator = schemaValidators.findIndex(
        (item) => item.callbackName
      );

      item.callbackName = item.callback.name;

      schemaField.validators.push(item);

      if (item.length) {
        formField.setValidators(item.callback(item.length));
      } else if (item.value) {
        formField.setValidators(item.callback(item.value));
      } else {
        formField.setValidators(item.callback);
      }

      formField.updateValueAndValidity();
    });
  }

  resolveDataFromSchema(data, resolverSchema = null) {
    const lazySchemaFields: ISchemaField[] = [];

    const dateResolver = (
      schemaFieldKey: string,
      relational: { key: string; type: SchemaInputType; format?: string }
    ) => {
      const schemaField = this.schema[schemaFieldKey];
      const fieldValue = this.formGroup.get(schemaFieldKey).value;
      const fieldParsedValue = Utils.toDateParse(fieldValue, ["DD.MM.YYYY"]);
      const relationlFieldValue = Utils.getPropByString(data, relational.key);
      const relationlFieldParsedValue =
        Utils.toDateInstance(relationlFieldValue);
      const relationlFieldNewDate: IBaseDateInput = Object.assign(
        {},
        {
          year: fieldParsedValue.year(),
          month: fieldParsedValue.month(),
          date: fieldParsedValue.date(),
        },
        relationlFieldParsedValue.get("hour")
          ? {
              hour: relationlFieldParsedValue.hour(),
              minute: relationlFieldParsedValue.minute(),
              second: relationlFieldParsedValue.second(),
            }
          : null
      );

      relationlFieldParsedValue.set(relationlFieldNewDate);

      if (relational.type == "date") {
        Utils.updateObjProp(
          data,
          relational.key,
          relationlFieldParsedValue.format(relational.format || "YYYY-MM-DD")
        );
      } else if (relational.type == "datetime") {
        Utils.updateObjProp(
          data,
          relational.key,
          relationlFieldParsedValue.format(
            relational.format || "YYYY-MM-DDTHH:mm:ss"
          )
        );
      }
    };

    const timeResolver = (
      schemaFieldKey: string,
      relational: { key: string; type: SchemaInputType; format?: string }
    ) => {
      const fieldValue = this.formGroup.get(schemaFieldKey).value;
      const fieldParsedValue = Utils.toDateParse(fieldValue, ["HH:mm:ss"]);
      const relationlFieldValue = Utils.getPropByString(data, relational.key);
      const relationlFieldParsedValue = Utils.toDateParse(relationlFieldValue);

      relationlFieldParsedValue.set({
        hour: fieldParsedValue.get("hour"),
        minute: fieldParsedValue.get("minute"),
        second: fieldParsedValue.get("second"),
      });

      Utils.updateObjProp(
        data,
        relational.key,
        relationlFieldParsedValue.format(
          relational.type == "datetime"
            ? relational.format || "YYYY-MM-DDTHH:mm:ss"
            : "HH:mm:ss"
        )
      );
    };

    Object.keys(this.filteredSchema).forEach((schemaFieldKey) => {
      const schemaField = this.schema[schemaFieldKey];
      const val = this.formGroup.get(schemaFieldKey).value;

      if (schemaField.type == "date") {
        dateResolver(schemaFieldKey, {
          key: schemaField.prop,
          type: schemaField.type,
          format: schemaField.format_type,
        });
      } else {
        Utils.updateObjProp(data, schemaField.prop, val);
      }

      if ("resolveTo" in this.schema[schemaFieldKey]) {
        lazySchemaFields.push(this.schema[schemaFieldKey]);
      }
    });

    lazySchemaFields.forEach((schemaField) => {
      const fieldValue = this.formGroup.get(schemaField.key).value;
      const resolverMapList = schemaField.resolveTo;

      resolverMapList.forEach((relationalField) => {
        const relationlFieldKey = relationalField.key;
        const relationlFieldValue = Utils.getPropByString(
          data,
          relationlFieldKey
        );

        if (
          fieldValue &&
          relationlFieldValue &&
          ["datetime", "date"].indexOf(relationalField.type) != -1
        ) {
          if (schemaField.type == "date") {
            dateResolver(schemaField.key, {
              key: relationlFieldKey,
              type: relationalField.type || "date",
              format: relationalField.format_type,
            });
          } else if (schemaField.type == "time-picker") {
            timeResolver(schemaField.key, {
              key: relationlFieldKey,
              type: relationalField.type,
              format: relationalField.format_type,
            });
          }
        } else if (relationalField.type === "textfield") {
          Utils.updateObjProp(data, relationlFieldKey, fieldValue);
        }
      });
    });

    return data;
  }

  schemaFieldCallback(fn: Function) {
    Object.keys(this.schema).forEach((schemaFieldKey) => {
      const schemaField = this.schema[schemaFieldKey];
      fn(schemaField);
    });
  }

  get filteredSchema(): ISchemaFieldType {
    const _schema = {};
    Object.keys(this.schema).forEach((schemaKey) => {
      const schemaField = this.schema[schemaKey];

      if (schemaField.ignore) {
        return;
      }

      _schema[schemaKey] = schemaField;
    });
    return _schema;
  }

  private attachValidators(
    schemaField: ISchemaField,
    formControl: UntypedFormControl,
    formGroup: UntypedFormGroup
  ) {
    if (!schemaField.validators?.length && !schemaField.required) {
      return;
    }

    schemaField.validators =
      (schemaField.validators &&
        schemaField.validators.map((validation) => {
          const callbackFnName = validation.callback;
          const validatorConfig = {
            ...validation,
            callback:
              typeof callbackFnName == "function"
                ? callbackFnName
                : this.validatorSchema[callbackFnName],
            callbackName:
              typeof callbackFnName == "function"
                ? callbackFnName.name
                : callbackFnName,
          };

          return validatorConfig;
        })) ||
      [];

    if (schemaField.required) {
      schemaField.validators.push({
        callback: BaseValidator.required,
      });
    }

    this.formValidatorService.setValidations(
      schemaField,
      formControl,
      formGroup
    );
  }

  pushArrayControl<T>(key: string, defaultValue: T = null) {
    const schemafield = this.schema[key];
    const formArray = this.formGroup.get(key) as UntypedFormArray;
    const nestedFormGroup = this.formBuilder.group({});

    schemafield.components.forEach((cmpSchema: ISchemaField) => {
      const formControl = new UntypedFormControl(null);

      nestedFormGroup.addControl(cmpSchema.key, formControl);

      if (defaultValue) {
        nestedFormGroup.patchValue(defaultValue);
      }

      this.attachValidators(cmpSchema, formControl, nestedFormGroup);
    });

    this._arrayBeforePushedEmitter.next();

    formArray.push(nestedFormGroup);

    this._arrayAfterPushedEmitter.next();
  }

  removeArrayControl(key: string, index: number) {
    const formArray = this.formGroup.get(key) as UntypedFormArray;

    formArray.removeAt(index);
  }

  validateForm() {
    const validate = (group: UntypedFormGroup | UntypedFormArray) => {
      Object.keys(group.controls).forEach((key: string) => {
        const abstractControl = group.controls[key];

        if (
          abstractControl instanceof UntypedFormGroup ||
          abstractControl instanceof UntypedFormArray
        ) {
          validate(abstractControl);
        } else {
          abstractControl.markAsDirty();
          abstractControl.markAsTouched();
          abstractControl.updateValueAndValidity();
        }
      });
    };

    validate(this.formGroup);
  }
}
