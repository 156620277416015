import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import { provideAuth, getAuth } from "@angular/fire/auth";

import { FirebaseService } from "./firebase.service";

import { AngularFireModule } from "@angular/fire/compat";

import { FIREBASE_CONFIG } from "./firebase.config";

@NgModule({
  declarations: [],
  imports: [CommonModule, AngularFireModule.initializeApp(FIREBASE_CONFIG)],
  providers: [
    /* provideFirebaseApp(() => initializeApp(FIREBASE_CONFIG)),
    provideAuth(() => getAuth()), */
    FirebaseService,
  ],
})
export class FirebaseModule {}
