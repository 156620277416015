<div class="sv-breadcrumb">
  <xng-breadcrumb [separator]="iconTemplate">
    <ng-container
      *xngBreadcrumbItem="let breadcrumb; let info = info; let first = first"
    >
      <!-- <ng-container>{{ breadcrumb }}</ng-container> -->
      <ng-container *ngIf="!translate || info?.translate === false"
        >{{ breadcrumb }}
      </ng-container>

      <ng-container *ngIf="translate && info?.translate !== false">
        <sv-locale-text [key]="breadcrumb" [modeInline]="true" />
      </ng-container>
    </ng-container>
  </xng-breadcrumb>

  <ng-template #iconTemplate>
    <i class="fas fa-chevron-right"></i>
  </ng-template>
</div>

<!-- <breadcrumb #parent>
  <ol class="breadcrumb">
    <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
      <li *ngIf="!route.terminal" class="breadcrumb-item">
        <a
          *ngIf="!translate || route?.route?.data?.translate === false"
          href=""
          [routerLink]="[route.url]"
          [queryParams]="handleReturnQueryParams(route.url)"
          >{{ route.displayName }}</a
        >
        <a
          *ngIf="translate && route?.route?.data?.translate !== false"
          href=""
          [routerLink]="[route.url]"
          [queryParams]="handleReturnQueryParams(route.url)"
          ><sv-locale-text
            [key]="route.displayName"
            [modeInline]="true"
          ></sv-locale-text
        ></a>
      </li>
      <li
        *ngIf="route.terminal"
        class="breadcrumb-item active"
        aria-current="page"
      >
        <ng-container
          *ngIf="!translate || route?.route?.data?.translate === false"
        >
          {{ route.displayName }}
        </ng-container>
        <ng-container
          *ngIf="translate && route?.route?.data?.translate !== false"
        >
          <sv-locale-text
            [key]="route.displayName"
            [modeInline]="true"
          ></sv-locale-text>
        </ng-container>
      </li>
    </ng-template>
  </ol>
</breadcrumb>
 -->
