import { environment } from "src/environments/environment";
import { InjectionToken } from "@angular/core";
import { IAppConfig } from "../core/ITypes";

export const APP_CONFIG = new InjectionToken<IAppConfig>("app.config");

export const AppConfig = {
  apiUrl: environment.baseUrl,
  environment: environment.production ? "production" : "staging",
  prefix: "lf_",
  cookieTimeoutSeconds: 3600,
  authKey: "lf_session",
  sessionLocaleKey: "lf_locale",
  lastActivityKey: "lf_last_activity_timestamp",
  cacheRequestTimeoutMins: 1,
  defaultLocale: "en",
  defaultProductRole: "PRODUCT_ASSORTMENT",
  tokenRenewalInterval: 20, //mins
  idleInterval: 90, //mins
  maxDropdownOptions: 2000,
};
