import { UntypedFormGroup } from "@angular/forms";
import { UntypedFormControl } from "@angular/forms";
import { ISchemaField } from "./../ITypes";
import { Injectable } from "@angular/core";
import { renderFn, Utils } from "src/app/commons/utils";

@Injectable()
export class FormValidatorService {
  private validators = [];
  private control: UntypedFormControl;
  private schemaField: ISchemaField;
  private formGroupInstance: UntypedFormGroup;

  setValidations(
    schemaControl: ISchemaField,
    control: UntypedFormControl,
    formGroupBuilder: UntypedFormGroup = null
  ) {
    this.validators = schemaControl.validators || [];
    this.control = control;
    this.schemaField = schemaControl;
    this.formGroupInstance = formGroupBuilder || (control.parent as UntypedFormGroup);

    if (
      Array.isArray(this.validators) &&
      this.validators.length &&
      this.control &&
      this.schemaField
    ) {
      const choosenValidators = this.validators
        .filter((validator) => validator.requiredIf)
        .map((validator) => {
          return this.conditionalValidator(() => {
            const parseCondition = Utils.stripTextAsArgs(
              validator.requiredIf,
              this.formGroupInstance.value
            );
            return renderFn(parseCondition);
          }, validator.callback);
        });

      const validatorsWithoutConditional = this.validators
        .filter((validator) => !validator.requiredIf)
        .map((item) => {
          const hasSchemaRequired = item.requiredSchema;

          if (item.length) {
            return hasSchemaRequired
              ? item.callback(item, item.length)
              : item.callback(item.length);
          } else if (item.value) {
            return hasSchemaRequired
              ? item.callback(item, item.value)
              : item.callback(item.value);
          }

          return hasSchemaRequired ? item.callback(item) : item.callback;
        });

      if (validatorsWithoutConditional.length) {
        choosenValidators.push(...validatorsWithoutConditional);
      }

      if (
        this.schemaField &&
        Array.isArray(this.schemaField.watchValidity) &&
        this.schemaField.watchValidity.length
      ) {
        choosenValidators.push(
          this.watchValidity(this.schemaField.watchValidity)
        );
      }

      if (choosenValidators.length) {
        this.attachValidator(choosenValidators);
      }
    } else {
      if (
        this.schemaField &&
        Array.isArray(this.schemaField.watchValidity) &&
        this.schemaField.watchValidity.length
      ) {
        this.attachValidator([
          this.watchValidity(this.schemaField.watchValidity),
        ]);
      }
    }
  }

  private conditionalValidator(predicate, validator) {
    return (formControl) => {
      const dependenField = predicate();

      if (dependenField) {
        return validator(formControl);
      } else {
        return null;
      }
    };
  }

  private attachValidator(choosenValidators = []) {
    this.control.setValidators(choosenValidators);
    this.control.updateValueAndValidity();
  }

  private watchValidity(watchedFields = []) {
    return (formControl) => {
      const formGroup: UntypedFormGroup = formControl.parent as UntypedFormGroup;

      if (formGroup) {
        for (let fieldKey of watchedFields) {
          if (formGroup.get(fieldKey)) {
            setTimeout(
              () => formGroup.get(fieldKey).updateValueAndValidity(),
              0
            );
          }
        }
      }

      return null;
    };
  }
}
