import { JwtService } from "./jwt.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class LoggedInAuthGuardService  {
  constructor(
    public autService: AuthService,
    public router: Router,
    private jwtService: JwtService
  ) {}

  canActivate(): boolean {
    if (this.jwtService.getToken()) {
      this.router.navigate(["/dashboard"]);
      return false;
    } else {
      return true;
    }
  }
}
