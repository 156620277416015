import { NgModule, Optional, SkipSelf } from "@angular/core";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";

import { CookieService } from "ngx-cookie-service";
import { ToastrModule } from "ngx-toastr";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BreadcrumbService } from "xng-breadcrumb";

import { CacheInterceptorService } from "./services/cache-interceptor.service";
import { GeneralService } from "src/app/commons/services";
import {
  ApiService,
  FormValidatorService,
  ChannelService,
  InterceptorService,
  CacheService,
  LocaleService,
} from "./services";
import {
  APP_CONFIG,
  AppConfig,
  NAV_MENU,
  Menu,
  CHANNEL_CONFIG,
  Channels,
  LOCALE_CONFIG,
  Locales,
} from "src/app/config";
import {
  provideNgxWebstorage,
  withLocalStorage,
  withNgxWebstorageConfig,
  withSessionStorage,
} from "ngx-webstorage";

class EnsureModuleLoadedOnceGuard {
  constructor(targetModule: any) {
    if (targetModule) {
      throw new Error(
        `${targetModule.constructor.name} has already been loaded. Import this module in the AppModule only.`
      );
    }
  }
}

const MODULES: any[] = [
  ToastrModule.forRoot({
    positionClass: "toast-bottom-right",
    preventDuplicates: true,
    closeButton: true,
    maxOpened: 1,
  }),
  NgbModule,
  // NgxWebstorageModule.forRoot({ prefix: "sv", separator: "-" }),
];

const PROVIDERS = [
  GeneralService,
  ApiService,
  CookieService,
  CacheService,
  BreadcrumbService,
  FormValidatorService,
  ChannelService,
  LocaleService,
  { provide: APP_CONFIG, useValue: AppConfig },
  { provide: CHANNEL_CONFIG, useValue: Channels },
  { provide: LOCALE_CONFIG, useValue: Locales },
  { provide: NAV_MENU, useValue: Menu },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CacheInterceptorService,
    multi: true,
  },
  provideHttpClient(withInterceptorsFromDi()),
  provideNgxWebstorage(
    withNgxWebstorageConfig({ prefix: "sv", separator: "-" }),
    withLocalStorage(),
    withSessionStorage()
  ),
];

/**
 * Represents Core module
 * consist of Routing modules
 * @export CoreModule
 * @class CoreModule
 */
@NgModule({
  imports: [...MODULES],
  declarations: [],
  providers: [...PROVIDERS],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
