import { IUserRole } from "../core/ITypes";

export interface IDisplayGroup {
  id?: number;
  name: any;
  lang?: any;
  C000: string;
  C001: number;
  C004: number;
  D001: number;
  key: string;
  value: string;
  displaygroup_set: number;
  displaygroup_set_name: string;
  displaygroup_set_active?: number;
  order?: number;
  active?: number;
}

export interface IDisplayGroupSet {
  id?: number;
  name: any;
  lang?: any;
  C000: string;
  C001: number;
  D001: number;
  C004: number;
  order: number;
  active?: number;
}

export interface IDisplayGroupField {
  name: string;
  value: any;
}

export interface ISort {
  prop: string;
  dir: string;
}

export interface IAuthCredentials {
  username: string;
  password: string;
}
export interface IProduct {
  C001?: string;
  C005: string;
  D001: string;
  D002: string;
  U001: string;
  U004: string;
  U005: string;
  F000: string;

  P000: string;
  P003: string;
  P005: string;

  U017: string;
  M001: string;
  M002: string;
  M003: string;

  S004: string;
  S007: string;
  U008: string;
  U009: string;
  U010: string;

  S005: string;
  S006: string;
  U012: string;
  U013: string;
  U014: string;

  L005: string;
  L006: string;
  L008: string;
  L009: string;
  L007: string;

  N002: string;
  N003: string;
  N001: string;
  N004: string;
  N007: string;
  N005: string;
  N008: string;
  N006: string;
  N011: string;

  F100: string;

  id: number;
  images: any;
}

export interface ICollicoEmittable {
  data?: Object;
  key: string;
  exist: boolean;
}

export enum ORDER_STATUS {
  PAYMENT_RECEIVED = "O901",
  // Order does not exist in Collico
  NEW_ORDER = "O902",
  CONFIRMATION_SENT = "O903",
  // Order exist in Collico
  NEW = "O904",
  //Others
  DELIVERY_PROCESS = "O905",
  DELIVERY_DISPATCHED = "O906",
  DELIVERY_CONFIRMATION_SENT = "O907",
  ORDER_REIMBURSED = "O908",
  COMPLETED = "O909",
  PAYMENT_FAILED = "O910",
  CANCELLED = "O911",
  DELIVERY_FAILED = "O912",
}

export enum DELIVERY_TYPE {
  NOT_PAID = "Not paid",
  CANCELLED = "Cancelled",
  NEW = "New",
  COMPLETED = "Completed",
  IN_PROGRESS = "InProgress",
}

export enum ORDER_ROW_CHANGE_TYPE {
  RECLAMATION = "reclamation",
  REIMBURSEMENT = "reimbursement",
  CANCELLATION = "cancellation",
  OTHER = "other",
}

export interface BREADCRUMB_OPTION {
  param?: any;
  parent?: boolean;
  translate?: boolean;
  routeAlias?: string | Object;
}

export interface SchemaOptionValue {
  [key: string]: {
    options: Array<{ label: string; value: any }>;
    defaultOptValue?: any;
  };
}

export interface IVendor {
  id?: number;
  C001?: number;
  name: string;
  code: string;
  active: boolean;
  type: VENDOR_TYPE;
}

export enum DELIVERY_METHOD {
  COLLICO = "CHD001",
  SMARTSHIP = "PO2103",
  SMARTSHIP_ALT = "PO2104",
  PICKUP = "PICKUP001",
}

export interface BaseEvent {
  type: string;
  payload?: any;
}

export type BaseEventCallback = (payload: any) => void;

export enum VENDOR_TYPE {
  REGULAR = 1,
  PREMIUM = 2,
}

export interface IProject {
  id?: number;
  title: string;
  start_date: string;
  end_date?: string;
  publish_date: string;
  products: IProjectProduct[];
  is_published: 0 | 1;
  productFields: string[];
}

export interface IProjectProduct {
  id: number;
  C001: number;
  D001: string;
  D002: boolean;
  P000: number;
  P003: number;
}

export interface ICampaign {
  id?: number;
  title: string;
  start_date: string;
  end_date: string;
  publish_date: string;
  products: ICampaignProduct[];
  is_published: 0 | 1;
  productFields: string[];
}

export interface ICampaignProduct {
  id: number;
  C001: number;
  D001: string;
  D002: boolean;
  P000: number;
  P003: number;
}

export interface IUser {
  id?: number;
  uuid?: string;
  name?: string;
  firstName: string;
  username: string;
  lastName: string;
  email: string;
  password: string;
  roles: IUserRole[];
}

export type IUserForm = Omit<IUser, "roles"> & {
  roles: { store: string; role: string }[];
  cpassword: string;
};

export interface ISupplier extends ISupplyPattern {
  supplier_id: string;
  title: string;
  full_name: string;
  reference_code: string;
  patterns: Array<ISupplyTimePattern>;
}

export interface ISupplyTimePattern {
  acceptOrder: number;
  lastCall: string;
  supplyLeadDays: number;
  supplyDay: string;
  supplyTime: string;
}

export interface ISupplierMerge extends ISupplyPattern {
  supplier_id: string;
  title: string;
  full_name: string;
  reference_code: string;
}

export interface ISupplierNew extends ISupplier {
  _supplyPattern: Array<ISupplyPattern>;
}

export interface ISupplyPattern {
  scheduleActive?: boolean;
  supplierName?: string;
  patternWeek1: string;
  patternWeek2: string;
  supplyOrderTime?: any;
  supplyLeadDays: any;
  supplyTime: any;
}
